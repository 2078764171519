
import { defineComponent, computed } from '@nuxtjs/composition-api'
import type { ProductAlgolia, ProductTileAlgolia } from '~/types/product'
import { priceLabels } from '~/config/label-parsers'

export default defineComponent({
  name: 'ProductStartingPriceLabel',
  props: {
    product: {
      type: Object as () => ProductTileAlgolia|ProductAlgolia,
      required: true
    }
  },
  setup (props) {
    const tags = computed(() => props.product.variationPrices?.lowest?.labelTags?.split(',').map(tag => tag.trim().toLowerCase()) || [])
    const isOnSale = computed(() => props.product?.finalPrice?.isOnSale)
    const label = computed(() => (priceLabels.find(label => tags.value.includes(label)) || (isOnSale.value ? 'tarjous' : '')))
    return {
      label,
      tags,
      isOnSale
    }
  }
})
